import React from 'react'
import AboutHome from '../components/AboutHome'
import Footer from '../components/Footer'
import Header from '../components/Header'
import OrderNow from '../components/OrderNow'
import OurPaper from '../components/OurPaper'
import PaperComponent from '../components/PaperComponent'
import Section from '../components/Section'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NewFeature from '../components/NewFeature'

const Home = () => {
      const description1="we're passionate about providing exceptional paper products that meet your needs. Our papers are carefully sourced and crafted with the utmost attention to detail, ensuring a premium experience every time. Whether you're printing important documents, creating stunning artwork, or writing a heartfelt note, our papers are designed to exceed your expectations."
      const location = useLocation()
      useEffect(()=>{
       window.scrollTo({
        top:0,
        left:0,
        behavior:'smooth'
       });
      },[location.pathname])
  return (
    <div className='h-screen '>
       <Header/>
       <Section/>
       <AboutHome/>
       <PaperComponent name="Perfect paper " desc={description1}/>
       <NewFeature/>
       <OurPaper/>
       <OrderNow/>
       <Footer/>
    </div>
  )
}

export default Home