import Logo2 from '../assests/images/logo2.png'
const AboutHome = () => {
  return (
    <div className='flex flex-col  text-end text-white px-3 md:px-5 md:flex-row md:mt-2 md:max-w-[90%] md:mx-auto' >
      <div className='flex justify-end md:justify-center items-center md:w-[45%]'>

        <img src={Logo2} alt="" className='w-auto  px-4 mb-16  md:w-auto h-[150px] md:h-[300px] md:object-contain' />
      </div>
        <div className='flex flex-col items-end justify-center md:w-[55%]'>
            <h1 className='text-5xl py-4 '>About us
            <div className='h-2 bg-white w-36 ml-10 rounded-l-lg mt-2'></div>
            </h1>
            
            <p className='my-4 mt-4 md:mt-12 text-lg '>At our paper supplier company, we understand the significance of paper in our daily lives. From printing important documents to creating beautiful artwork, and sending handwritten notes, paper plays a vital role in our lives. That's why we carefully source the best raw materials and pay attention to detail in crafting our products. We take pride in providing high-quality paper products that meet your needs and exceed your expectations. Discover the difference our commitment to quality makes and elevate your paper experience with our exceptional products. </p>
        </div>
    </div>
  )
}

export default AboutHome