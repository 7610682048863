import { useLocation } from 'react-router-dom'
import About1 from '../components/About'
import AboutHome from '../components/AboutHome'
import Footer from "../components/Footer"
import Header from "../components/Header"
import OrderNow from "../components/OrderNow"
import OurPaper from "../components/OurPaper"
import PaperComponent from "../components/PaperComponent"
import { useEffect } from 'react'

const About = () => {
      const description2 = "We are dedicated to providing excellent customer service to our customers. Our friendly and knowledgeable team is available to answer any questions and provide personalized solutions to meet your needs. We also offer fast and reliable shipping to ensure that you receive your products in a timely manner."
       const location = useLocation()
      useEffect(()=>{
       window.scrollTo({
        top:0,
        left:0,
        behavior:'smooth'
       });
      },[location.pathname])
  return (
    <div className='h-screen '>
        <Header/>
        <About1/>
        <AboutHome/>
        <PaperComponent name="Customer Service" desc={description2}/>
        <OurPaper/>
        <OrderNow/>
        <Footer/>
    </div>
  )
}

export default About