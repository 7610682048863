import {AiOutlineClose} from 'react-icons/ai'
import { Link,  } from 'react-router-dom'
import {Link as Linkscroll} from 'react-scroll'
const MenuItems = ({closeNav,setCloseNav,active}) => {
  return (
    <div className={` absolute right-0 top-0 bg-[#010201] w-[90%] h-screen transition-opacity duration-200 z-50 sm:hidden`}>
        <AiOutlineClose className='text-white fixed top-4 right-4' size={28} onClick={()=>setCloseNav(false)}/>
        <ul className='flex justify-center items-center flex-col h-[60%] space-y-12'>
           <Link to='/'><li className={`navlist2 transition-colors duration-150 ${active === '/' ? 'text-[#FFD600]':'text-white'}`} onClick={()=>setCloseNav(false)}>Home</li></Link>
                    <Link to='/about'><li className={`navlist2 transition-colors duration-150 ${active === '/about' ? 'text-[#FFD600]':'text-white'}`} onClick={()=>setCloseNav(false)}>About us</li></Link>
                   <Linkscroll to="order" spy={true} smooth={true} offset={-100} duration={700}> <li className='navlist2' onClick={()=>setCloseNav(false)}>Order</li></Linkscroll>
                    <Linkscroll to="contact" spy={true} smooth={true} offset={50} duration={700}><li className='navlist2' onClick={()=>setCloseNav(false)}>Contact us</li></Linkscroll>
        </ul>
    </div>
  )
}

export default MenuItems