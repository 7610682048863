import Print from '../assests/images/print.png'
import PaperImg from '../assests/images/paperimgae.png'
const OrderNow = () => {
  return (
    <div className='flex relative ' id='order'>
        <div className=' w-[100%] -z-50 absolute left-0  top-0 md:block md:w-[100%] md:relative'>
            <div className='relative  flex items-center justify-center md:block'>
                
            <div className='absolute left-0 hidden w-[400px] h-96 md:block bg-[#FFD600] md:h-80 md:w-[120px] lg:w-[200px] lg:h-96'>
            </div>
            <img src={Print} alt="" className='absolute  self-center top-4 brightness-50 md:brightness-100 rounded-lg w-auto h-[360px] md:h-[240px] lg:h-[300px] md:left-14 lg:top-10 ' />
            </div>
        </div>
        <div className=' flex items-center justify-center flex-col space-y-9 mt-12 mb-12'>
            <h1 className='text-4xl relative text-white '>Order Now
            <div className='underlinestyle3 h-2 bg-white  w-28 absolute left-8 mt-3'  ></div>
            </h1>
            <p className='text-white max-w-[85%] sm:max-w-[50%] text-center'>At our paper supplier company, we understand the significance of paper in our daily lives. From printing important documents to creating beautiful artwork, and sending handwritten notes, paper plays a vital role in our lives.</p>
            <button className='px-8 py-3 text-black bg-[#FFD600] font-semibold'><a href='tel:+251902676667'>Contact us</a></button>

        </div>
        {/* <div className='block w-screen  md:hidden absolute left-0 top-0'>
            <img src={PaperImg} alt="" className='sm:object-contain md:mr-56 h-[400px] absolute top-0 -z-10 md:relative brightness-50  md:h-[500px] md:brightness-100 ' />
        </div> */}

    </div>
  )
}

export default OrderNow