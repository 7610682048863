import PaperCard from "./PaperCard"
import paper1 from '../assests/images/paper1.png'
import paper2 from '../assests/images/paper2.png'
import paper3 from '../assests/images/paper3.png'
const OurPaper = () => {
  return (
    <div className="text-white px-5 mt-16 flex items-center justify-center flex-col ">
        <h1 className="text-4xl py-3 md:ml-[8%] self-center sm:ml-0 md:self-start">Why Our Paper
         <div className="underlinestyle2 h-2 bg-white  w-36   mt-2 md:ml-[8%]"></div>
        </h1>
       
        <div className="py-8 flex flex-col items-center justify-center sm:flex-row sm:space-x-20 flex-wrap  ">
            <PaperCard name="Printing Documents" desc=" High-quality prints for professional needs." img={paper1}/>
            <PaperCard name="Creating Artwork" desc="Inspiring creativity with vibrant colors." img={paper2}/>
            <PaperCard name="Handwritten Note" desc="Timeless messages with a personal touch." img={paper3}/>
        </div>
    </div>
  )
}

export default OurPaper