import Aboutimg from '../assests/images/about.png'

const About = () => {
  return (
    <div className='relative h-[86vh] md:h-[95vh] bg-gradient-to-b from-gray-900/30 to-[#000000] '>
        <img src={Aboutimg} alt="" className='w-screen object-cover -z-20 absolute h-[65vh] md:h-[90vh] md:object-left-top'/>
        <div className='text-white flex justify-center text-center items-center  flex-col h-[100vh] md:pt-36'>
            <h1 className='text-6xl'>About us
                <div className='bg-white h-2 w-40 rounded-lg mt-2 ml-9'></div>
            </h1>
            <p className='px-5 mt-6  md:max-w-[80%] md:text-lg'>At our paper supplier company, we understand the significance of paper in our daily lives. From printing important documents to creating beautiful artwork, and sending handwritten notes, paper plays a vital role in our lives. That's why we carefully source the best raw materials and pay attention to detail in crafting our products. We take pride in providing high-quality paper products that meet your needs and exceed your expectations. Discover the difference our commitment to quality makes and elevate your paper experience with our exceptional products.</p>
        </div>
    </div>
  )
}

export default About