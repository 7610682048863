import { Link } from 'react-scroll'
import Img1 from '../assests/images/img1.png'
const Section = () => {
  return (
    <section className='mt-10 bg-gradient-to-b from-gray-900/30 to-[#000000] h-[70vh] md:max-h-[100vh] md:h-screen '>
        <div className='relative top-0 left-0 -z-50'>

        <img src={Img1} alt=""  className='w-screen overflow-x-hidden h-[70vh]  object-cover md:object-left-top md:max-h-[100vh] md:h-auto absolute top-0 left-0'/>
        </div>
        <div className='flex h-auto w-screen flex-col pt-[25%] sm:pt-[13%] items-start px-5 sm:px-14 text-white sm:max-w-[90%] lg:max-w-[50%] mb-9'>
          <h1 className='text-2xl sm:text-4xl py-4 leading-[1.3]'>We are committed to providing high-quality paper products at affordable prices.</h1>
          <p className='sm:text-lg mb-3'> Whether you are a business or an individual, we have a limited but carefully curated selection of paper products to meet your needs.</p>
        <Link to="contact" spy={true} smooth={true} offset={50} duration={700}>  <button className='bg-[#D69C2A] rounded-full px-6 py-2 text-black font-semibold text-lg'>Contact us</button></Link>
        </div>
      
    </section>
  )
}

export default Section