const PaperCard = ({name,desc,img}) => {
  return (
    <div className=" flex items-end justify-center ">
        <div className="bg-gradient-to-b from-gray-900/30 to-[#000000]   w-[350px] h-[250px] my-5">

        <img src={img} alt="" className="relative w-[350px] h-[250px] mb-10 -z-40  shadow-yellow-300/30 shadow-2xl"/>
        </div>
        <div className="absolute mb-14">

        <h1 className="text-3xl text-[#FFD600] italic font-semibold">{name}</h1>
        <p>{desc}</p>
        </div>
    </div>
  )
}

export default PaperCard