import Logo2 from "../assests/images/logo2.png";
const Footer = () => {
  return (
    <div
      className="bg-gradient-to-r from-yellow-400/10 via-black/0 to-yellow-500/20 my-5 px-1 h-72 md:h-96 flex mt-32"
      id="contact"
    >
      <div className="hidden md:flex justify-start items-end pb-4 md:items-center md:justify-between md:w-[35%] ">
        <img
          src={Logo2}
          alt=""
          className="h-[140px] pl-4 object-contain md:h-[150px] w-auto md:pl-40 "
        />
        <div className="h-36 w-2 rounded-lg bg-[#FFD600] hidden md:block"></div>
      </div>
      <div className="text-white flex justify-center   md:justify-center items-center flex-col w-[100%] md:w-[55%] ">
        <h1 className="text-4xl ">
          Contact us
          <div className="w-36 bg-[#FFD600] rounded-lg md:rounded-l-lg md:rounded-lg h-2 ml-5 mb-4 mt-2 md:ml-4"></div>
        </h1>
        <div className="flex flex-col max-w-[220px]   md:max-w-[260px] text-center items-center">
          <span>0902676667</span>
          <a href="https://t.me/perfectpaper" target="_blank" className="mb-1">
            <span className="leading-[1.5">@perfectpaper</span>
          </a>
          <span className="leading-[1.5]">RHAMEZi SHARE COMPANY</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
