import Logo from '../assests/images/logo2.png'
import {BiMenuAltRight} from 'react-icons/bi'
import MenuItems from './MenuItems'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {Link as Linkscroll} from 'react-scroll'
const Header = () => {
    const [closeNav,setCloseNav]=useState(false);
    const [active,setActive]=useState('/')
    const location = useLocation();
    // console.log(closeNav)
    useEffect(()=>{
      setActive(location.pathname);

    },[location.pathname])
  return (
    <div className="text-white px-3 py-3 flex fixed  top-0 left-0 w-screen  justify-between bg-[#010201] sm:bg-[#010201] items-center sm:bg-gradient-to-r sm:opacity-[100%] z-50">
         <img src={Logo} alt="" className='w-auto h-[50px] ml-4 md:ml-28 md:object-contain ' />
         <div>
            <BiMenuAltRight size={45} className='text-[#FFD600] sm:hidden' onClick={()=>setCloseNav(true)}/>
            <div className='hidden sm:flex'>
                <ul className='flex space-x-5 mr-5 md:space-x-12 md:mr-32 '>
                    <Link to='/'><li className={`navlist2 transition-colors duration-150 ${active === '/' ? 'text-[#FFD600]':'text-white'}`}>Home</li></Link>
                    <Link to='/about'><li className={`navlist2 transition-colors duration-150 ${active === '/about' ? 'text-[#FFD600]':'text-white'}`}>About us</li></Link>
                   <Linkscroll to="order" spy={true} smooth={true} offset={-100} duration={700}> <li className='navlist2'>Order</li></Linkscroll>
                    <Linkscroll to="contact" spy={true} smooth={true} offset={50} duration={700}><li className='navlist2'>Contact us</li></Linkscroll>
                </ul>
            </div>
            <div className={`${closeNav ? '': 'hidden '}`}>

            <MenuItems closeNav={closeNav} setCloseNav={setCloseNav} active={active}/>
            </div>
         </div>
    </div>
  )
}

export default Header