

const NewFeature = () => {
  return (
    <div className="max-w-[90%] mx-auto flex justify-center items-center">
        <div className="text-white border-2 border-slate-300 md:max-w-[480px] px-6 py-6 rounded-lg space-y-4 flex-col flex">
            <span className="font-semibold text-3xl">Announcement
            <div className='bg-white h-1 w-40 rounded mt-2 ml-8 md:ml-0 md:rounded-r-lg'></div>
            </span>
            <span className="font-semibold">New Premium Paper</span>
            <p className="">Aurora paper is renowned for its silky texture and ethereal glow, offering a luxurious writing experience. Its surface allows ink to glide effortlessly, producing vibrant and crisp lines that dry instantly without smudging. The paper's exceptional quality enhances the colors of inks and paints, making it a favorite among writers, artists, and calligraphers. Each sheet's smoothness and subtle patterns add an elegant touch to any written work. Aurora's unparalleled excellence makes it the epitome of premium paper, treasured for both its beauty and performance. Ideal for important documents, creative projects, and personal correspondence, Aurora elevates the art of writing and drawing to new heights.</p>
        </div>
    </div>
  )
}

export default NewFeature