import PaperImg from '../assests/images/paperimgae.png'
const PaperComponent = ({name,desc}) => {
  return (
    <div className='flex justify-center flex-col items-center px-5 py-11 md:flex-row relative md:max-w-[85%] md:mx-auto'>

        <div className='text-white max-w-[100%] md:max-w-[50%] flex items-center flex-col justify-center md:items-start'>
            <h1 className='text-4xl mb-6 md:mb-2 '>{name}
                <div className='bg-white h-2 w-40 rounded mt-4 ml-8 md:ml-0 md:rounded-r-lg'></div>
            </h1>
            <p className='text-center text-lg md:text-start md:max-w-[88%]'>{desc}</p>
        </div>
        <div className='w-screen  md:block md:max-w-[100%] md:w-[50%]'>
            <img src={PaperImg} alt="" className='sm:object-contain md:mr-56 h-[400px] absolute top-0 -z-10 md:relative brightness-50  md:h-[500px] md:brightness-100 ' />
        </div>
    </div>
  )
}

export default PaperComponent